.greenlayer{
  border-top-left-radius: 699px;
  border-bottom-left-radius: 9px;
  margin-top: 90px;
}
.greenlayerLeft{
  border-top-right-radius: 100px;
  margin-bottom: 700px;
  margin-left: -290px;
   
}